// syntax highlighing
@import './vendor/prism-a11y-dark.css';

// Bootstrap Overrides
@import './base/_bootstrap_overrides';

// Bootstrap
@import './vendor/_bootstrap';

.btn {
    border-radius: 0;
}

.header-link {
    color: inherit;
    user-select: none;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.vortex-link {
    color: invert($primary);

    &:hover {
        color: invert($dark);
    }
}

.hr-custom-primary {
    border-top: 2px solid change-color($primary, $alpha: 1);
}

.remove-li-border > li {
    border: none;
}

img {
    max-width: 100%
}